.App-header {
    background-color: #fff;
    position: relative;
    h1 {
        z-index: 9;
    }
}
.Colour-Bar {
    position: absolute;
    z-index: 1;
    width: 200vw;
    height: 200px;
    left: -25%;
    top: calc(45vh - 64px);
    overflow: hidden;
    transform: rotate(13deg);
    background: -moz-linear-gradient(bottom, #F2C94C 0%, #F2C94C 33%, #F2994A 33%, #F2994A 66%, #56CCF2 66%, #56CCF2 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#F2C94C), color-stop(33%,#F2C94C), color-stop(33%,#F2994A), color-stop(66%,#F2994A), color-stop(66%,#56CCF2), color-stop(100%,#56CCF2));
    background: -webkit-linear-gradient(bottom, #F2C94C 0%,#F2C94C 33%,#F2994A 33%,#F2994A 66%,#56CCF2 66%,#56CCF2 100%);
    background: -o-linear-gradient(bottom, #F2C94C 0%,#F2C94C 33%,#F2994A 33%,#F2994A 66%,#56CCF2 66%,#56CCF2 100%);
    background: -ms-linear-gradient(bottom, #F2C94C 0%,#F2C94C 33%,#F2994A 33%,#F2994A 66%,#56CCF2 66%,#56CCF2 100%);
    background: linear-gradient(to bottom, #F2C94C 0%,#F2C94C 33%,#F2994A 33%,#F2994A 66%,#56CCF2 66%,#56CCF2 100%);
}

h1, h2, h3, h4, h5, h6 {
    font-family: Nokora, Roboto, Sans-Serif;
    font-weight: 100;
}

p {
    font-family: Nokora, Roboto, Sans-Serif;
    font-weight: normal;  
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
    background-color: #56CCF2;
}

.content-block {
    min-height: 100vh;
}

footer {
    display: grid;
    // grid-template-columns: repeat(3, 1fr);
    background-color: #282c34;
}
.column {
    padding: 1rem;
    color: white;
}
.container {
    display: flex;
    width: 100%;
    justify-content: center;
}
.card {
    padding:0.5rem;
    width: 100%;
}
.card-header {
    background-color: white;
}
.card-body {
    text-align: left;
}